<div class='flex_row mt-4'>
    <p-table #dt [columns]="cols" [value]="riderTableDetails">
        <ng-template pTemplate="caption">
            <span class="ui-float-label" style="margin:2px 5px">
                <span style="width: 4%;display: inline-flex;">
                </span>
                
                <div style="display: flex;align-items: center;margin-bottom: 24px;">
                    <div class="label-container">
                        <span class="label-title">Global Search</span>
                        <div class="search-input-container">
                            <img src="../../../../../assets/search-icn.svg">
                            <input class="search-input" pInputText type="text" pInputText size="auto" placeholder="Search" pInputText size="50"
                                placeholder="Global Search" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                style="height: 36px; width: auto;">
                        </div>
                    </div>

                    <div class="label-container">
                        <span class="label-title">Create New</span>
                        <div class="create-buttons-container">
                            <!-- *ngIf="checkPermission('OVERWRITING_FARE_EDIT')"  -->
                            <div  (click)="createFarePopUp()" class="create-button">&#x20B9; <span style="margin-left: 5px;">Create new Fare</span></div>
                            <div  (click)="createDistancePopup()" class="create-button dist-btn"><img src="../../../../../assets/cust-dist-icon.svg" style="margin-right: 5px;"> Create custom Distance</div>
                        </div>
                </div>
            </div> 

                    <!-- <span *ngIf="checkPermission('OVERWRITING_FARE_EDIT')">
                        <button  pButton class="submit" icon="pi pi-file-o" iconPos="left" label="Create New Fare"
                        (click)="createFarePopUp()" style="margin-right: 0.5em; margin: 5px;"></button>
                    </span> -->
               
                <div class="customs-container">

                    <div class="custom-rules">Custom Rules</div>
                    <div class="custom-tabs">
                        <div class="custom-tab" (click)="changeTab(1)" [ngClass]="customTab == 1?'selected':''">Custom Fare</div>
                        <div class="custom-tab"(click)="changeTab(2)" [ngClass]="customTab == 2?'selected':''">Custom Distance</div>
                    </div>

                    <div class="bottom-line">
                        <div class="tab" [ngClass]="customTab == 1?'active tab1':''"></div>
                        <div class="tab" [ngClass]="customTab == 2?'active tab2':''"></div>
                    </div>

                </div>
            </span>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>ID</th>
                   <th>Created at</th>
                   <th>Location 1</th>
                   <th style="width:78px">Radius 1</th>
                   <th>Location 2</th>
                   <th style="width:78px">Radius 2</th>
                   <th style="width:85px">System Fare</th>
                   <th style="width:85px">Actual {{customTab == 1?'Fare':'Distance'}}</th>
                   <th style="width: 100px;">Status</th>
                   <th style="width: 100px;">Source</th>
                   <th>Action</th>
            </tr>

        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns">

                    <td *ngIf="col.isVisible" style="word-break: break-word; text-align: center;">
                        <ng-container [ngSwitch]="col.field">
                            <ng-container *ngSwitchCase="'sNo'">
                                {{rowIndex + 1}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'createdAt'">
                                {{rowData.createdAt}} <br>
                                {{rowData.createdByName}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'fromAddress'">
                                {{rowData.fromAddress}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'fromRadius'">
                                {{rowData.fromRadius }}
                            </ng-container>
                            <ng-container *ngSwitchCase="'toAddress'">
                                {{rowData.toAddress }}
                            </ng-container>
                            <ng-container *ngSwitchCase="'toRadius'">
                                {{rowData.toRadius}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'systemFare'">
                                {{rowData.systemFare}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'status'">
                                <div style="display: flex;">
                                    <!-- [disabled]="!checkPermission('OVERWRITING_FARE_EDIT')" -->
                                    <p-inputSwitch (onChange)="handleBlockSwitchChange($event, rowData)"
                                        [(ngModel)]="rowData.status" >
                                    </p-inputSwitch>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'allowedSource'" class="source">
                                <i style="font-size: 13px;" title="{{rowData.allowedSource}}" class="fa fa-file-text" aria-hidden="true"></i>
                            </ng-container>
                            <ng-container *ngSwitchCase="'action'">
                                <!-- *ngIf="checkPermission('OVERWRITING_FARE_EDIT')" -->
                                <div style="display: flex; margin-left: 20px;" >
                                    <button type="button" pButton label="Edit"
                                        (click)="customTab == 1? updateFarePopUp(rowData.id,rowData):updateDistancePopUp(rowData.id,rowData)"
                                        style="margin-right: 0.5em; margin-left: 0.5em; height: 32px; font-size: 11px;"></button>
                                    <button type="button" pButton label="Delete" (click)="customTab == 1? deleteFarePopUp(rowData.id): deleteDistancePopUp(rowData.id)"
                                        style="margin-right: 0.5em; margin-left: 0.5em; height: 32px;font-size: 11px; background: red;"></button>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{ rowData[col.field] }}
                            </ng-container>

                        </ng-container>
                    </td>

                </ng-container>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog header="New File Creation" [(visible)]="addNewFile" [baseZIndex]="10000" [modal]="true" [draggable]="false"
    [resizable]="false" [style]="{
        maxWidth: '850px',
        minWidth: '800px',
        maxHeight: '550px',
        minHeight: '500px',
        'z-index': '999'
      }" [minY]="70" [maximizable]="false" [baseZIndex]="1">
    <div class="modal-body">
        <form #f="ngForm" (ngSubmit)='customTab == 1?createFare(f):createDistance(f)'>
            <div style="display: flex;">

                <div class="form-group" style="width: 450px;">
                    <label for="">Location 1</label>
                    <input pInputText type="text" class="form-control customFormInput" placeholder="" name="from"
                        [(ngModel)]="pickupLocation" autocorrect="off" autocapitalize="off" spellcheck="off" required
                        #from (keydown.enter)="$event.preventDefault()" (ngModelChange)="resetLatLong('from')"
                        (click)="resetScreenToDispalyMap()" />
                </div>
                <div class="form-group" style="margin-left: 90px;">
                    <label for="" style="margin-left: 15px;">Radius 1 (m)</label>
                    <input pInputText type="number" class="form-control customFormInput" placeholder="Enter First Radius"
                     id="fromRadius" name="fromRadius" ngModel
                     #p="ngModel"/>
                </div>
            </div>
            <div style="display: flex;">
                <div class="form-group" style="width: 450px;">
                    <label for="">Location 2</label>
                    <input pInputText type="text" class="form-control customFormInput" [(ngModel)]="dropoffLocation"
                        placeholder="" id="to" name="to" autocorrect="off" autocapitalize="off" #to spellcheck="off"
                         
                        (keydown.enter)="$event.preventDefault()" (ngModelChange)="resetLatLong('to')"
                        (click)="resetScreenToDispalyMap()" />
                </div>
                <div class="form-group" style="margin-left: 90px;">
                    <label for="" >Radius 2 (m)</label>
                    <input pInputText type="number" class="form-control customFormInput" placeholder="Enter Radius 2"
                         id="toRadius" name="toRadius" ngModel #toRadius="ngModel"/>
                </div>
            </div>
            <div class="form-group">
                <label for="">System Calculated {{customTab == 1?'Fare':'Distance'}}</label>
                <input pInputText type="number" class="form-control customFormInput"[placeholder]="customTab == 1?' Enter System Fare':'Enter System Distance'"
                 id="systemFare" name="systemFare" ngModel #systemFare="ngModel"/>
            </div>
            <div class="form-group">
                <label for="">updated Calculated  {{customTab == 1?'Fare':'Distance'}}</label>
                <input pInputText type="number" class="form-control customFormInput" [placeholder]="customTab == 1?' Enter Actual Fare':'Enter Actual Distance'"
                     id="actualFare" name="actualFare" ngModel #actualFare="ngModel"/>
            </div>
            <div class="form-group" style="margin-top: 5px;">
                <div>
                    <label for="">Allowed Source</label>
                    <p-multiSelect [options]="sources" [(ngModel)]="allowedSource" (onChange)="select($event)" [ngModelOptions]="{standalone: true}"
                    [style]="{ width: '100%' }"></p-multiSelect>
                </div>
                <div style="padding: 20px 0px;">
                    <label for="" style="margin-right: 5px;">Status</label>
                    <p-inputSwitch ngModel id="status" name="status" #status="ngModel">
                    </p-inputSwitch>
                </div>
               
            </div>
            <div class="form-group mt-2">
                <button class="btn btn-primary" [disabled]="f.invalid">Create</button>
            </div>
        </form>
    </div>

</p-dialog>
<p-dialog header="Update Fare" [(visible)]="updateNewFile" [baseZIndex]="10000" [modal]="true" [draggable]="false"
    [resizable]="false" [style]="{
        maxWidth: '850px',
        minWidth: '800px',
        maxHeight: '550px',
        minHeight: '500px',
        'z-index': '999'
      }" [minY]="70" [maximizable]="false" [baseZIndex]="1">
    <div class="modal-body">
            <div style="display: flex;">
                <div class="form-group" style="width: 450px;">
                    <label for="">Location 1</label>
                    <input pInputText type="text" class="form-control customFormInput" placeholder=""  disabled
                        [(ngModel)]="fileData.fromAddress" autocorrect="off" autocapitalize="off" spellcheck="off" required
                         (keydown.enter)="$event.preventDefault()" (ngModelChange)="resetLatLong('from')"
                        (click)="resetScreenToDispalyMap()" />
                </div>
                <div class="form-group" style="margin-left: 90px;">
                    <label for="" style="margin-left: 15px;">Radius 1 (m)</label>
                    <textarea class="form-control customFormInput" pInputText size="50" rows="1" placeholder="Enter First Radius"
                    [(ngModel)] = "fileData.fromRadius"
                     ></textarea>
                </div>
            </div>
            <div style="display: flex;">
                <div class="form-group" style="width: 450px;">
                    <label for="">Location 2</label>
                    <input pInputText type="text" class="form-control customFormInput" [(ngModel)]="fileData.toAddress" disabled
                        placeholder=""  autocorrect="off" autocapitalize="off" #to spellcheck="off"
                        (keydown.enter)="$event.preventDefault()" (ngModelChange)="resetLatLong('to')"
                        (click)="resetScreenToDispalyMap()" />
                </div>
                <div class="form-group" style="margin-left: 90px;">
                    <label for="" >Radius 2 (m)</label>
                    <textarea class="form-control customFormInput" pInputText size="50" rows="1" placeholder="Enter Radius 2" [(ngModel)] = "fileData.toRadius"></textarea>
                </div>
            </div>
            <div class="form-group">
                <label for="">System Calculated Fare</label>
                <textarea class="form-control customFormInput" pInputText size="50" rows="1" placeholder="Enter System Fare" [(ngModel)] = "fileData.systemFare"></textarea>
            </div>
            <div class="form-group">
                <label for="">updated Calculated Fare</label>
                <textarea class="form-control customFormInput" pInputText size="50" rows="1" placeholder="Enter Actual Fare" [(ngModel)] = "fileData.actualFare"
                   ></textarea>
            </div>
            <div class="form-group" style="margin-top: 5px;">

                <div>
                    <label for="">Allowed Source</label>
                    <p-multiSelect [options]="sources" [(ngModel)]="allowedSource" (onChange)="select($event)" [ngModelOptions]="{standalone: true}"
                    [style]="{ width: '100%' }"></p-multiSelect>
                </div>
                <div>
                    <label for="" style="margin-right: 5px;">Status</label>
                    <p-inputSwitch [(ngModel)] = "fileData.status">
                    </p-inputSwitch>
                </div>

            </div>
            <div class="form-group mt-2">
                <button class="btn btn-primary" (click)="customTab == 1? updateFare(): updateFareDistance()">Update</button>
            </div>
    </div>

</p-dialog>
