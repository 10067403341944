import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CookieService } from 'ngx-cookie-service';
import { AppService } from './services/app.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { COOKIE_URL,COOKIE_SUFFIX, isProdEnv } from './config/apiRouteConfig';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  ssoId;
  gmailTokenCheckTimer;

  constructor(public appService: AppService, private router: Router, private _ckService: CookieService,
    private loader: Ng4LoadingSpinnerService) {

  }

  ngOnInit() {
    google.accounts.id.initialize({
      client_id: '393352348407-cctavkfvbphf4cuc3957n3nnv7r94uun.apps.googleusercontent.com',
      auto_select: true,
      cancel_on_tap_outside: false
    });
    this.checkUserLogin();
    this.gmailTokenCheckTimer = setInterval(() => {
      this.checkUserLogin();
    }, 10800000)

    this.appService.isLoad = false;
    this.appService.isValid = false;
    this.loader.show();
    this.appService.checkToken().subscribe(
      (res: any) => {
        
        this.loader.hide();

        this.appService.isLoad = true;
        if (res.statusCode != 200) { 
          this.appService.isValid = false;
          this._ckService.set("accessToken"+COOKIE_SUFFIX, null, 5000, "/",COOKIE_URL,true,"Lax")
          this.router.navigateByUrl('/login');
          
        } else {
          let name
          this.ssoId = res.response.sso_id
          if(localStorage.getItem('name')){
              this.appService.name = localStorage.getItem('name');
          
          } else if(res.response.username){ 
              let username = res.response.username
              let usernameArr = username.split('.')
              if(usernameArr.length == 3){
                  name = usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase()
              } else{
                  usernameArr = username.split('@')
                  name = usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase()
              }
              this.appService.name = name
          
          }
          
          localStorage.setItem('ssoId',res.response.sso_id)
          this.appService.getRolesForUser(this.ssoId).subscribe(
            (res: any) => {
              if(res.statusCode >=300){
                this.appService.isValid = false;
                alert(res.message)
                this._ckService.set("accessToken"+COOKIE_SUFFIX, null, 5000, "/",COOKIE_URL,true,"Lax")
                this.router.navigateByUrl('/login');
                return
              }

              localStorage.setItem("userRoles",JSON.stringify(res.response))
              this.appService.isValid = true;
            }
          )
          //this.socketService.setConnection();

        }
        
      },
      (err) => {
        //console.log(err);

      }
    )
  }
  checkUserLogin(){
    if(isProdEnv)
    google.accounts.id.prompt((notification) => {
      if(notification.getNotDisplayedReason()){
        this.appService.isValid = false;
        this._ckService.set("accessToken"+COOKIE_SUFFIX, null, 5000, "/",COOKIE_URL,true,"Lax")
        this.router.navigateByUrl('/login');
      }
    });
  }


}

